<template>
  <div>
    <!-- <b-card>      
      <b-card class="bg-light-secondary">
        <div class="d-flex align-items-center justify-content-between pb-1">
          <h4 class="">New Scan</h4>
        </div>
        <div class="d-flex mb-1">
          <span class="d-flex" style="width: 60%">
            <v-select
              v-model="assetName"
              label="text"
              :options="asset_options"
              placeholder="--Search Asset--"
              @search="fetchOptions"
              @input="loadAgentTypes"
              autocomplete
              class="w-100"
              :reduce="(assetName) => assetName.value"
            />
            <b-button
              class="btn-radius-hide-left"
              variant="outline-success"
              size="sm"
              @click="gotoAddAsset()"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Add New Asset'"
              ><feather-icon icon="PlusIcon"
            /></b-button>
          </span>
          <b-button
            style="width: 20%"
            class="ml-1"
            variant="primary"
            @click="openScanModal()"
            :disabled="assetName === null"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>
              <feather-icon icon="SearchIcon" class="mr-50" />Scan</span
            >
          </b-button>
          <b-button
            style="width: 20%"
            class="ml-1"
            variant="primary"
            @click="openScheduleModal"
            :disabled="assetName === null"
          >
            <feather-icon icon="ClockIcon" class="mr-50" />Schedule
          </b-button>
        </div>
      </b-card>
    </b-card> -->
    <b-tabs v-model="tabIndex">
      <b-tab title="Attack Surface">
        <AttackSurfaceScanResult :tabIndex="tabIndex" ref="attackSurfaceRef" />
      </b-tab>
      <b-tab title="ASM">
        <b-card>
          <b-card class="bg-light-secondary" no-header>
            <div class="d-flex align-items-center">
              <h4 class="">Search</h4>
            </div>
            <div class="d-flex mb-1">
              <b-form-input
                class="ml-1"
                id="name"
                v-model="name_filter"
                style="width: 38%"
                name="name"
                placeholder="Search with Asset Name"
              />
              <b-form-input
                class="ml-1"
                id="name"
                v-model="ip_address_filter"
                style="width: 38%"
                name="name"
                placeholder="Search with IP Address"
              />
              <b-button
                style="width: 10%"
                class="ml-1"
                variant="secondary"
                @click="refreshFn"
                size="sm"
              >
                <span>
                  <feather-icon icon="RefreshCwIcon" class="mr-50" />Reset</span
                >
              </b-button>
              <b-button
                style="width: 10%"
                class="ml-1"
                variant="primary"
                @click="load"
                :disabled="searchDisable"
              >
                <span v-if="isloading"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>
                  <feather-icon icon="SearchIcon" class="mr-50" />Search</span
                >
              </b-button>
            </div>
          </b-card>
          <div v-if="isloadingtable">
            <b-spinner type="border" small></b-spinner>
          </div>
          <div v-else>
            <div v-if="!loading" class="clearfix">
              Total of {{ total_assets_count }} Assets
            </div>
            <b-table-simple
              v-if="items.length"
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>#</b-th>
                  <b-th>IP Address</b-th>
                  <!-- <b-th>OS</b-th> -->
                  <b-th>Asset Name</b-th>
                  <b-th>Port</b-th>
                  <b-th>Protocol</b-th>
                  <b-th style="white-space: nowrap">Vulnerability Count</b-th>
                  <b-th style="text-align: center">URL</b-th>
                  <b-th style="text-align: center">Technology</b-th>
                  <b-th style="text-align: center">Details</b-th>
                  <b-th style="text-align: center">Screenshots</b-th>
                  <!-- <b-th v-for="(field, index) in fields" :key="index" v-if="field.visible">{{ field.label }}</b-th> -->
                </b-tr>
              </b-thead>
              <b-tbody v-for="(result, index) in items" :key="index">
                <b-tr>
                  <b-td :rowspan="result.nmap_result.ports.length + 1">
                    {{ index + 1 + perPage * (currentPage - 1) }}
                  </b-td>
                  <b-td
                    :rowspan="result.nmap_result.ports.length + 1"
                    class="text-center"
                  >
                    {{ result.nmap_result.address }}
                  </b-td>
                  <!-- <b-td :rowspan="result.nmap_result.ports.length + 1"
            ><ul v-for="os in result.nmap_result.os" :key="os">
              <li>{{ os.name }}<br /></li></ul
          ></b-td> -->
                  <b-td :rowspan="result.nmap_result.ports.length + 1">
                    <router-link
                      :to="'/assets/scan/' + result.nmap_result.asset_id"
                      >{{ result.asset_name }}</router-link
                    ></b-td
                  >
                </b-tr>
                <b-tr
                  v-for="(nmapPort, npid) in result.nmap_result.ports"
                  :key="npid"
                >
                  <b-td> {{ nmapPort.portid }}/{{ nmapPort.protocol }} </b-td>
                  <b-td>{{ nmapPort.service_name }}</b-td>
                  <b-td style="white-space: nowrap">
                    <span
                      class="align-items-center"
                      v-for="(key, value) in result.vulnerabilities_counts[
                        nmapPort.portid
                      ]"
                      :key="key"
                    >
                      <b-avatar
                        v-if="value === 'critical'"
                        style="background-color: #a020f0 !important"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: result.asset_name,
                              severity: 'critical',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'high'"
                        variant="danger"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: result.asset_name,
                              severity: 'high',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'medium'"
                        variant="warning"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: result.asset_name,
                              severity: 'medium',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'low'"
                        variant="success"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: result.asset_name,
                              severity: 'low',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                      <b-avatar
                        v-if="value === 'info'"
                        style="background-color: #7367f0 !important"
                        class="cursor-pointer text-capitalize"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top
                        :title="value.charAt(0).toUpperCase() + value.slice(1)"
                        button
                        @click="
                          $router.push({
                            path: '/assets/vulnerabilities',
                            query: {
                              port: nmapPort.portid,
                              asset_name: result.asset_name,
                              severity: 'info',
                              tabIndex: 1,
                            },
                          })
                        "
                      >
                        {{ key }}
                      </b-avatar>
                    </span>
                  </b-td>
                  <b-td>
                    <span
                      v-for="(whatwebPort, wp) in result.whatweb_result"
                      :key="wp"
                    >
                      <ul
                        v-if="
                          parseInt(whatwebPort.port) ===
                          parseInt(nmapPort.portid)
                        "
                      >
                        <li>
                          <strong>Target:</strong> {{ whatwebPort.target
                          }}<br />
                          <strong>HTTP Status:</strong>
                          {{ whatwebPort.http_status }}<br />
                        </li>
                      </ul>
                    </span>
                  </b-td>
                  <b-td>
                    <span
                      v-for="(whatwebPort, wpid) in result.whatweb_result"
                      :key="wpid"
                    >
                      <ul
                        v-if="
                          parseInt(whatwebPort.port) ===
                          parseInt(nmapPort.portid)
                        "
                      >
                        <li><strong>HTML :</strong> {{ whatwebPort.html }}</li>
                        <li>
                          <strong>jquery :</strong> {{ whatwebPort.jquery }}
                        </li>
                        <li>
                          <strong>nginx :</strong> {{ whatwebPort.nginx }}
                        </li>
                        <li>
                          <strong>wordpress :</strong>
                          {{ whatwebPort.wordpress }}
                        </li>
                        <li>
                          <strong>x_hacker :</strong> {{ whatwebPort.x_hacker }}
                        </li>
                        <li>
                          <strong>Powered By :</strong
                          >{{ whatwebPort.poweredby }}
                        </li>
                        <!-- <li>
                  <strong>x_ua_compatible :</strong>
                  {{ whatwebPort.x_ua_compatible }}
                </li> -->
                      </ul>
                    </span>
                  </b-td>
                  <b-td>
                    <span
                      v-for="(whatwebPort, wpid) in result.whatweb_result"
                      :key="wpid"
                    >
                      <ul
                        v-if="
                          parseInt(whatwebPort.port) ===
                          parseInt(nmapPort.portid)
                        "
                      >
                        <li>
                          <strong>HTTP Server OS:</strong>
                          {{ whatwebPort.http_server_os }}
                        </li>
                        <li>
                          <strong>HTTP Server :</strong>
                          {{ whatwebPort.http_server }}
                        </li>
                        <li>
                          <strong>Title :</strong> {{ whatwebPort.title }}
                        </li>
                        <li v-if="whatwebPort.redirect_location != null">
                          <strong>Redirect Location :</strong>
                          {{ whatwebPort.redirect_location }}
                        </li>
                        <li>
                          <strong>Uncommon Header :</strong>
                          {{ whatwebPort.uncommon_headers }}
                        </li>
                      </ul>
                    </span>
                  </b-td>
                  <b-td>
                    <!-- <span v-for="(whatwebPort, wp) in result.whatweb_result" :key="wp"> -->
                    <div v-for="(assetImage, index) in imageUrls" :key="index">
                      <span v-for="imageport in assetImage" :key="imageport">
                        <span
                          v-if="
                            parseInt(imageport.port) ===
                              parseInt(nmapPort.portid) &&
                            parseInt(imageport.asset_id) ===
                              result.nmap_result.asset_id
                          "
                        >
                          <!-- <a :href="result.asset_name" target="_blank"> -->
                          <span
                            @click="gotoImageModal(imageport.url)"
                            class="cursor-pointer"
                          >
                            <img
                              :key="index"
                              :src="imageport.url"
                              height="150px"
                              width="200px"
                              alt="Image"
                            />
                          </span>
                          <!-- </a> -->
                        </span>
                      </span>
                    </div>
                    <!-- </span> -->
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
            <b-pagination-nav
              v-model="currentPage"
              :numberOfPages="total"
              :total-rows="rows"
              :link-gen="linkGen"
              :per-page="perPage"
              :align="pagination_pos"
              use-router
              v-if="!loading"
              class="mt-1"
            />
          </div>
        </b-card>
      </b-tab>
      <!-- <b-tab title="Scan Progress">
        <AttackSurfaceProgress
          :agent_id="agentId"
          :tabIndex="tabIndex"
          ref="attackSurfaceProgress"
        />
      </b-tab>
      <b-tab title="Schedule Progress">
        <AttackSurfaceScheduleProgress
          :agent_id="agentId"
          :tabIndex="tabIndex"
          ref="attackSurfaceScheduleProgress"
        />
      </b-tab> -->
    </b-tabs>
    <b-modal
      v-model="openImageModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <img :key="index" :src="imageurl" width="100%" alt="Image" />
    </b-modal>
    <b-modal
      ref="modal-normal-scan"
      title="Scan Options"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AddNormalScan
        :agent_type_id="agentId"
        :asset_id="assetName"
        :callBack="afterScan"
        :agent_type="agentType"
      />
    </b-modal>
    <b-modal
      ref="modal-schedule-scan"
      title="Schedule Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AttachScheduler
        :agent_type_id="agentId"
        :asset_id="assetName"
        :callBack="afterScan"
        :agent_type="agentType"
      />
    </b-modal>
    <b-modal
      ref="modal-add-asset"
      title="Add Asset"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AddAssetOption
        :closeSidebar="closeModal"
        :assetRedirct="assetRedirct"
        :isHide="true"
      />
    </b-modal>
  
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BImage,
  BModal,
  BCardText,
  BSpinner,
  BPaginationNav,
  BDropdown,
  BDropdownForm,
  BFormCheckbox,
  BTableColumn,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormInput,
  VBTooltip,
  BTab,
  BTabs,
  BAvatar,
} from "bootstrap-vue";
import moment from "moment";
import BSTableSimple from "@/components/bs_table/TableSimple";
import vSelect from "vue-select";
import AddNormalScan from "../Asset/components/AddNormalScan.vue";
import AttachScheduler from "../Asset/components/attach_scheduler.vue";
import AddAssetOption from "../AssetGroup/componets/addAssetOption.vue";
import AttackSurfaceProgress from "./components/scanProgress.vue";
import AttackSurfaceScheduleProgress from "./components/scheduleProgress.vue";
// import PerformAttackSurfaceScan from "./components/performAttackSurfaceScan.vue";
import AttackSurfaceScanResult from "./components/attackSurfaceScanResult.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BImage,
    BModal,
    BCardText,
    BSpinner,
    BPaginationNav,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BTableColumn,
    BSTableSimple,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardCode,
    BFormInput,
    vSelect,
    AddNormalScan,
    AttachScheduler,
    AddAssetOption,
    AttackSurfaceProgress,
    AttackSurfaceScheduleProgress,
    // PerformAttackSurfaceScan,
    AttackSurfaceScanResult,
    BTab,
    BTabs,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      total_assets_count: 0,
      items: [],
      fields: [
        { label: "#", visible: true },
        { label: "IP Address", visible: true },
        { label: "OS", visible: true },
        { label: "Port", visible: true },
        { label: "Protocol", visible: true },
        { label: "URL", visible: false },
        { label: "Technology", visible: true },
      ],
      xfields: [
        { key: "index", label: "#", visible: true },
        // { key: "nmap_result", label: "Nmaper", visible: true },
        // { key: "whatweb_result", label: "WhatWeb", visible: true },
        { key: "address", label: "IP Address", visible: true },
        { key: "asset_name", label: "Hostname", visible: true },
        { key: "os", label: "OS", visible: false },
        // { key: "country", label: "country", visible: true },
        // { key: "addrtype", label: "Type", visible: true },

        // { key: "ports", label: "Port", visible: false },
        { key: "protocol", label: "Protocol", visible: true },
        { key: "technology", label: "Technology", visible: true },
        { key: "url", label: "URL", visible: false },
      ],
      loading: false,
      name_filter: null,
      ip_address_filter: null,
      isloading: false,
      imageUrls: [],
      assetName: null,
      asset_options: [],
      org_id: this.$store.state.app.org_id,
      agent_items: [],
      agentId: null,
      agentType: null,
      assetRedirct: true,
      searchDisable: false,
      isloadingtable: false,
      openImageModal: false,
      imageurl: "",
      // openPerformAttackSurfaceModal: false,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load(false);
      this.onVerticalCollapse();
    },
    tabIndex: function (newVal, oldVal) {
      if (this.tabIndex === 1) {
        this.load();
        this.loadAgentTypes();
      }
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    if (this.tabIndex == 1) {
      this.load();
      this.loadAgentTypes();
    }
    // if (this.$route.query.asmInitiateScan) {
    //   this.openPerformAttackSurfaceModal = true;
    //   this.$router.replace({ query: null });
    // }
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    // performAttackSurface() {
    //   this.openPerformAttackSurfaceModal = true;
    // },
    // closePerformAttackSurface() {
    //   this.openPerformAttackSurfaceModal = false;
    //   this.$refs.attackSurfaceRef.load();
    // },

    load: function (reset = true) {
      this.isloadingtable = true;
      this.searchDisable = true;
      this.isloading = true;
      // if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/attack-surface?page=" +
        this.currentPage +
        "&items_per_page=" +
        this.perPage;
      if (this.name_filter != null) {
        url = url + "&asset_name=" + this.name_filter;
      }
      if (this.ip_address_filter != null) {
        url = url + "&ip_address=" + this.ip_address_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.total_assets_count = res.data.count;

        this.items.forEach((result) => {
          const assetImageUrls = []; // Array to store image URLs for the current asset
          result.whatweb_result.forEach((whatwebPort, wp) => {
            if (whatwebPort.image_data) {
              this.getImageUrl(whatwebPort.image_data)
                .then((imageUrl) => {
                  assetImageUrls.push({
                    port: whatwebPort.port,
                    url: imageUrl,
                    asset_id: result.nmap_result.asset_id,
                    link: result.asset_name,
                  });
                })
                .catch((error) => {
                  console.error("Error getting image URL:", error);
                });
            }
          });
          this.imageUrls.push(assetImageUrls); // Add the array for the current asset
        });
      });
      this.isloadingtable = false;
      this.isloading = false;
      this.searchDisable = false;
    },

    getImageUrl(imageData) {
      return new Promise((resolve, reject) => {
        const imageInfo = JSON.parse(imageData);
        const data = {
          file_obj: imageInfo,
        };
        const options = {
          method: "POST",
          data: data,
          url:
            process.env.VUE_APP_BASEURL +
            "asset/attack-surface/download-screenshot/",
          responseType: "blob",
        };

        this.$http(options)
          .then((res) => {
            console.log("Image Data:", res.data);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            resolve(url);
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
            reject(error);
          });
      });
    },
    gotoImageModal(imageurl) {
      this.imageurl = imageurl;
      this.openImageModal = true;
    },
    refreshFn() {
      this.name_filter = null;
      this.ip_address_filter = null;
      this.load();
    },
    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.org_id +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type?query=true",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        console.log(res.data, "agent type list");
        this.agent_items = res.data.filter(
          (item) => item.agent_type_code === "attack_surface"
        );

        const agentTypeInfo = this.agent_items.map((item) => ({
          agentType: item.agent_type_code,
          agentId: item.agent_type_id,
        }));

        this.agentType = agentTypeInfo.map((info) => info.agentType)[0] || "";
        this.agentId = agentTypeInfo.map((info) => info.agentId)[0] || null;
      });
    },
    openScanModal: function () {
      this.$refs["modal-normal-scan"].show();
    },
    afterScan: function (callBackParams) {
      this.$refs[callBackParams].hide();
      this.assetName = null;
      this.load();
      this.$refs.attackSurfaceProgress.searchFn();
      this.$refs.attackSurfaceScheduleProgress.searchFn();
    },
    openScheduleModal: function (id, type) {
      this.$refs["modal-schedule-scan"].show();
    },
    gotoDetails() {
      this.tabIndex = 1;
      // this.$router.push("/scans");
    },
    gotoAddAsset() {
      this.$refs["modal-add-asset"].show();
    },
    closeModal() {
      this.$refs["modal-add-asset"].hide();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .dark-layout .vs__dropdown-toggle {
  background: #283046;
  border-color: #3b4253;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.vs__dropdown-toggle {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btn-radius-hide-left {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-color: #d8d6de !important;
}
[dir] .dark-layout .btn-radius-hide-left {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-color: #3b4253 !important;
}
</style>