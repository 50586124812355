<template>
  <b-card-code>
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <v-select
            id="asset_filter"
            class="ml-1 rounded"
            style="width: 80%; background-color: white"
            v-model="asset_filter"
            label="text"
            :options="asset_filter_options"
            @search="fetchOptions"
            placeholder="Asset Name or Nickname"
            autocomplete
            @input="searchFn"
          />
          <b-button
            style="width: 12%"
            class="ml-auto"
            variant="primary"
            @click="searchFn"
            :disabled="searchDisable"
          >
            Search
          </b-button>
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        id="atTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        v-if="items.length"
        hover
        responsive
        style="min-height: 200px"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(asset_name)="data">
          <div class="primary" v-if="data.item.asset != null">
            <router-link
              class="text-truncate"
              :to="'/assets/scan/' + data.item.asset.asset_id"
              >{{ data.item.asset.asset_nickname }} ({{
                data.item.asset.asset_name
              }})</router-link
            >
          </div>
          <div class="primary" v-if="data.item.assetgroup != null">
            <router-link
              :to="'/assets/group/' + data.item.asset_group + '/assets'"
              >{{ data.item.assetgroup.group_name }} (Asset Group)</router-link
            >
          </div>
        </template>
        <template #cell(scan_status)="data">
          <b-badge variant="primary" v-if="data.item.scan_status == 0"
            >Scheduled</b-badge
          >
          <b-badge
            variant="warning"
            v-else-if="data.item.scan_status == 1 || data.item.scan_status == 6"
            >In Progress</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.scan_status == 2"
            >Completed</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.scan_status == 5"
            >Pre-scheduled</b-badge
          >
          <b-badge variant="danger" v-else>Failed</b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
          >
            <b-dropdown-item
              v-if="
                data.item.scan_status == 0 ||
                data.item.scan_status == 1 ||
                data.item.scan_status == 2
              "
              @click="refresh(data.item.scan_id)"
              >Refresh</b-dropdown-item
            >
            <b-dropdown-item
              v-if="data.item.scan_status == 2 && data.item.agent_type == 3"
              @click="assets_discovered(data.item.scan_id)"
              >Assets Discovered</b-dropdown-item
            >
            <b-dropdown-item
              v-if="
                data.item.agenttype.agent_type_code == 'attack_surface' &&
                data.item.scan_status == 2
              "
              @click="getSummary(data.item.scan_id)"
              >Scan Summary</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              @click="gotoDelete(data.item.scan_id, data.item.domain)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
          <!-- <p>
            Navigate to detailed insights ,
            <span
              class="font-weight-bold text-success"
              @click="
                $router.push({
                  path: '/Discovery',
                  query: { tabIndex: 2 },
                })
              "
              >Click here</span
            >
          </p> -->
        </div>
      </div>
    </div>

    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
      use-router
      v-if="!loading"
    />
    <!-- Right Sidebar starts -->
    <b-sidebar
      id="sidebar-asset-discover-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
    >
      <AssetsDiscover :scan_id="scan_id" :closeSidebar="closeSidebar" />
    </b-sidebar>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text> Do you really want to delete {{ domain }} ? </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteGroup()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <!-- </b-card-code> -->
    <!-- </b-tab> -->
    <!-- </b-tabs> -->
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssetsDiscover from "../../AssetDiscovery/components/assets_discovered.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      scan_id: 0,
      selected_group: 0,
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "asset_name", label: "Domain" },
        { key: "scan_status", label: "Status" },
        { key: "errors", label: "Errors" },
        { key: "timezone", label: "Timezone" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: null,
      scheduleorganization: null,
      domain_organization: null,
      organizations: [],
      name_filter: "",
      domain: "",
      loading: false,
      schedule_selected: null,
      schedule_data: [
        { value: null, text: "--Select Schedule--" },
        { value: 5, text: "Once in a month" },
        { value: 6, text: "Once in a three-month" },
        { value: 7, text: "Once in a six-month" },
        { value: 8, text: "Once in a year" },
      ],
      openDeleteModal: false,
      domain_scan_id: "",
      domain: "",
      agent_id: null,
      asset_filter: null,
      asset_filter_options: [{ value: null, text: "--Select Asset--" }],
      searchDisable: false,
    };
  },
  props: {
    agent_id: {
      type: Number,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
    tabIndex: function (newVal, oldVal) {
      if (this.agent_id && this.tabIndex === 1) {
        this.load();
      }
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created() {
    if (this.agent_id) {
      this.load();
    }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (this.is_staff) {
        this.loading = true;
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org",
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
          this.loading = false;
        });
      } else {
        this.organization = this.$store.state.app.org_id;
        this.searchFn();
      }
      this.searchFn();
    },

    load: function () {
      this.loading = true;
      //   if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/scan/?query=true&agent_type=" +
          this.agent_id +
          "&org_id=" +
          this.organization,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    gotoDelete(scan_id, domain) {
      this.openDeleteModal = true;
      this.domain_scan_id = scan_id;
      this.domain = domain;
    },
    deleteGroup: function () {
      // if (confirm("Do you really want to delete this Scan?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/scan/" +
          this.domain_scan_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.searchFn();
        }
      });
      // }
    },

    searchFn: function (reset = true) {
      this.onVerticalCollapse();
      this.searchDisable = true;
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/scan?query=true&agent_type=" +
        this.agent_id;
      if (this.currentPage) {
        url = url + "&page=" + this.currentPage;
      }
      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.asset_filter != null) {
        url = url + "&asset_id=" + this.asset_filter.value;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        this.searchDisable = false;
      });
    },

    refresh: function (id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/" + id + "/refresh/",
      };
      var self = this;
      this.$http(options).then((res) => {
        // this.load();
        this.searchFn();
      });
    },
    assets_discovered: function (id) {
      this.scan_id = id;
      this.$root.$emit(
        "bv::toggle::collapse",
        "sidebar-asset-discover-handler"
      );
    },
    closeSidebar: function () {
      this.$root.$emit(
        "bv::toggle::collapse",
        "sidebar-asset-discover-handler"
      );
    },
    getSummary(id) {
      this.$router.push({ name: "Scan Summary", params: { id: id } });
    },
    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;

        this.$http(a_options).then((res) => {
          self.asset_filter_options = [];
          // self.asset_filter_options.push({ value: null, text: "--Searching for "+search });
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_filter_options.push(a);
          });
        });
      }
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
