<template>
  <!-- <b-card-code> -->
  <div>
    <div>
      <h4>Perform an attack surface scan</h4>
      <p class="text-secondary">
        Please add your external assets here. This will automatically scan your
        assets and give an overview about your attack surface.
      </p>
    </div>
    <validation-observer ref="PerformAttackSurfaceForm" #default="{ invalid }">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="performAttackSurface"
      >
        <b-form-group label-for="v-username">
          <b-form-input
            id="asset_name"
            v-model="asset_name"
            name="asset_name"
            placeholder="IP, Domain, CIDR or Range"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || asset_name === '' || disable"
          class="my-1"
          size="sm"
          style="width: 140px"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Start Scan</span>
        </b-button>

        <b-button
          variant="outline-primary"
          class="my-1 ml-1"
          size="sm"
          style="width: 140px"
          @click="skipNow()"
          :disabled="disable"
          v-if="!$store.state.app.is_onboading_completed"
        >
          <span v-if="isloadingSkip"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Skip</span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
  <!-- </b-card-code> -->
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      asset_name: "",
      isloading: false,
      disable: false,
      isloadingSkip: false,
    };
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    isOnboarding: {
      type: Boolean,
      required: true,
    },
  },
  created: function () {},
  methods: {
    performAttackSurface: function () {
      this.$refs.PerformAttackSurfaceForm.validate().then((success) => {
        this.isloading = true;
        this.disable = true;
        if (success) {
          //call to axios
          var self = this;
          let data = {
            target: [this.asset_name],
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "scan/asm-scan/",
          };
          this.$http(options)
            .then((res) => {
              if (res.data.status === "success") {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.isloading = false;
                this.disable = false;
                this.asset_name = "";
                if (this.isOnboarding) {
                  this.$store.dispatch(
                    "app/getOrgRefresh",
                    this.$store.state.app.org_id
                  );
                } else {
                  this.closeModal();
                }
              } else {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
                this.isloading = false;
                this.disable = false;
              }
            })
            .catch((err) => {
              this.isloading = false;
              this.disable = false;
            });
        }
      });
    },
    skipNow: function () {
      this.isloadingSkip = true;
      this.disable = true;
      var self = this;
      let data = {
        is_onboading_completed: true,
      };
      const options = {
        method: "PATCH",
        headers: { "content-type": "application/json" },
        data: data,
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.$store.state.app.organizationId +
          "/",
      };
      this.$http(options).then((res) => {
        if (res.status == 200) {
          this.$store
            .dispatch("app/getOrgRefresh", this.$store.state.app.org_id)
            .then(() => {
              this.$router.push("/");
            });
          // this.$store.dispatch(
          //   "app/getOrgRefresh",
          //   this.$store.state.app.org_id
          // );
          // this.$router.push("/");
        }
        this.isloadingSkip = false;
        this.disable = false;
      });
      // }
      // });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>