<template>
  <b-card no-header>
    <div class=" d-flex justify-content-end">
      <b-button
        size="sm"
        class="ml-1"
        variant="primary"
        @click="performAttackSurface()"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />New Scan
      </b-button>
    </div>
    <!-- {{ items }} -->
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <br />
    <div v-if="!loading">
      <div v-if="items.length">
        <b-table
          id="atTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          style="min-height: 200px"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(asset_name)="data">
            <a
              class="text-truncate"
              @click="redirectToAssetOverview(data.item.asset.asset_id)"
            >
              <span>
                {{ data.item.asset.asset_name }}
              </span>
            </a>
            <!-- {{ data.item.asset.asset_name}} -->
          </template>
          <template #cell(scan_status)="data">
            <b-badge variant="primary" v-if="data.item.scan_status == 0"
              >Scheduled</b-badge
            >
            <b-badge
              variant="warning"
              v-else-if="
                data.item.scan_status == 1 || data.item.scan_status == 6
              "
              >In Progress</b-badge
            >
            <b-badge variant="success" v-else-if="data.item.scan_status == 2"
              >Completed</b-badge
            >
            <b-badge variant="primary" v-else-if="data.item.scan_status == 5"
              >Pre-scheduled</b-badge
            >
            <b-badge variant="danger" v-else>Failed</b-badge>
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | formatdate }}</span>
          </template>
          <template #cell(finished_at)="data">
            <span>{{ data.item.finished_at | formatdate }}</span>
          </template>
          <template #cell(actions)="data">
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap"
            >
              <b-dropdown
                size="sm"
                class="mr-1"
                variant="outline-primary"
                text="Actions"
              >
                <b-dropdown-item @click="checkRefresh(data.item.master_scan_id)"
                  >Refresh</b-dropdown-item
                >
              </b-dropdown>
              <b-button
                variant="primary"
                size="sm"
                @click="
                  gotoScanSummary(
                    data.item.master_scan_id,
                    data.item.asset.asset_id
                  )
                "
              >
                Scan Summary
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :total-rows="rows"
        :link-gen="linkGen"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="atTable"
        use-router
      />
    </div>
    <b-modal
      v-model="openPerformAttackSurfaceModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <PerformAttackSurfaceScan :closeModal="closePerformAttackSurface" />
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import AssetsDiscover from "../AssetDiscovery/components/assets_discovered.vue";
import PerformAttackSurfaceScan from "../components/performAttackSurfaceScan.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    // AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
    PerformAttackSurfaceScan,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      scan_id: 0,
      selected_group: 0,
      pagination_pos: "center",
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "asset_name", label: "Asset Name" },
        { key: "scan_type", label: "Scan Type" },

        { key: "scan_status", label: "Status" },
        { key: "created_at", label: "Created At" },
        { key: "finished_at", label: "Finished At" },
        { key: "scan_created_by", label: "Created By" },
        { key: "actions", label: "Actions" },
      ],
      loading: false,
      asset_id: null,
      openPerformAttackSurfaceModal: false,
    };
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  watch: {
    tabIndex: {
      handler(newVal, oldVal) {
        if (newVal == 0) {
          this.load();
        }
      },
      immediate: true,
    },

    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  created() {
    if (this.$route.query.asmInitiateScan) {
      this.openPerformAttackSurfaceModal = true;
      this.$router.replace({ query: null });
    }
    this.load();
  },
  mounted() {
    this.onVerticalCollapse();
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function () {
      this.loading = true;

      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/list-master-scan",
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    gotoDelete(scan_id, domain) {
      this.openDeleteModal = true;
      this.domain_scan_id = scan_id;
      this.domain = domain;
    },
    deleteGroup: function () {
      // if (confirm("Do you really want to delete this Scan?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/scan/" +
          this.domain_scan_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.searchFn();
        }
      });
      // }
    },
    performAttackSurface() {
      this.openPerformAttackSurfaceModal = true;
    },
    closePerformAttackSurface() {
      this.openPerformAttackSurfaceModal = false;
      this.$refs.attackSurfaceRef.load();
    },

    searchFn: function (reset = true) {
      this.onVerticalCollapse();
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/list-master-scan?query=true" +
        "&page=" +
        this.currentPage;

      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    redirectToAssetOverview(asset_id) {
      const assetId = asset_id;
      this.$router.push(`/assets/scan/${assetId}`);
    },
    gotoScanSummary(scan_id, asset_id) {
      this.asset_id = asset_id;
      const master_scan_id = scan_id;
      // this.$router.push(`/attack-surface/scan-summary/${master_scan_id}`);
      this.$router.push({
        path: `/attack-surface/scan-summary/${master_scan_id}`,
        query: { asset_id: asset_id },
      });
    },
    checkRefresh(scan_id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/refresh-master-scan?master_scan_id=" +
          scan_id,
      };
      var self = this;
      this.$http(options).then((res) => {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.load();
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
